<template>
    <div>
        <section class="ftco-section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 heading-section text-center ftco-animated">                        
                        <h2 class="mb-4">{{GetLabel(0)}}</h2>     

                        <h5 class="mt-2">คุณสมบัติของการเป็นสมาชิก</h5>  
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <ul>
                                    <li>ต้องเป็นคริสตชนในสังกัดคริสตจักรโปรแตสแตนท์ในประเทศไทย</li>
                                    <li>เป็นผู้บรรลุนิติภาวะแล้ว (ยกเว้นสมาชิกวิสามัญ)</li>
                                    <li>เป็นผู้มีความประพฤติเรียบร้อย</li>
                                </ul>
                            </div>
                            <div class="col-md-3"></div>
                        </div>

                        <h5 class="mt-2">ขั้นตอนการสมัครสมาชิก</h5>     
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <ul>
                                    <li>กรอกข้อมูลในฟอร์มด้านล่างให้ครบถ้วน</li>
                                    <li>ชำระเงินโดยการ โอนเงินผ่านธนาคาร
                                        <p>ธนาคารกรุงเทพ สาขาถนนตากสิน</p>
                                        <p>ชื่อบัญชี สมาคม ดนตรีคริสตจักร</p>
                                        <p>เลขบัญชี 158-414561-7</p>
                                        <!-- <p>หรือผ่าน QR Code ด้านล่างนี้</p>
                                        <img class="float-rigth" style="width:50%; height:auto" :src="require('../assets/images/QR_Bank.jpg')"> -->
                                    </li>
                                    <li>แจ้งชำระเงินผ่าน Line@ 
                                        <p>QR Code Line@</p>
                                        <img class="float-rigth" style="width:50%; height:auto" :src="require('../assets/images/QR_Line.jpg')">
                                        หรือ <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=mrj7435f" target="_blank" >คลิกที่นี่</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-3"></div>
                        </div>              
                    </div>
                </div>

                <div class="row" v-if="OnRegister">
                    <div class="col-sm-12">
                        <div class="row">                            
                            <div class="col-md-6">
                                <label>{{GetLabel(1)}}</label>
                                <select class="form-control" v-model="RegisterData.mbtype_id" @change="GetMemberFee()">
                                    <option v-for="(op, index) in optMBType" :key="index"
                                        v-bind:value="op.value"
                                    >
                                        {{op.text}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label>{{GetLabel(2)}}</label>
                                <select class="form-control" v-model="RegisterData.church_id">
                                    <option v-for="(op, index) in optChurch" :key="index"
                                        v-bind:value="op.value"
                                    >
                                        {{op.text}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-md-12 mt-1">
                                <p class="text-danger" style="white-space: pre;">{{RegisFee}}</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-2">
                                <label>{{GetLabel(3)}}</label>
                                <b-input v-model="RegisterData.title" ></b-input>
                            </div>
                            <div class="col-md-5">
                                <label>{{GetLabel(4)}}</label>
                                <b-input v-model="RegisterData.name" ></b-input>
                            </div>
                            <div class="col-md-5">
                                <label>{{GetLabel(5)}}</label>
                                <b-input v-model="RegisterData.surname" ></b-input>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <label>{{GetLabel(6)}}</label>
                                <b-input v-model="RegisterData.nickname" ></b-input>
                            </div>
                            <div class="col-md-4">
                                <label>{{GetLabel(7)}}</label>
                                <b-input type="date" v-model="RegisterData.birthdate" ></b-input>
                            </div>
                            <div class="col-md-4">
                                <label>{{GetLabel(8)}}</label>
                                <b-input v-model="RegisterData.phone" ></b-input>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-6">
                                <label>{{GetLabel(9)}}</label>
                                <textarea class="form-control"  v-model="RegisterData.addr" rows="4"></textarea>
                            </div>

                            <div class="col-md-6">
                                <div class="row">
                                    <label>{{GetLabel(10)}}</label>
                                    <div>
                                        <b-input v-model="RegisterData.email" ></b-input>
                                    </div>
                                </div>

                                <div class="row">
                                    <label>{{GetLabel(11)}}</label>
                                    <div>
                                        <b-input v-model="RegisterData.lineid" ></b-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-sm-6">
                                <label>{{GetLabel(12)}}</label>
                                <select class="form-control" v-model="RegisterData.rbch_id">
                                    <option v-for="(op, index) in optRBCH" :key="index"
                                        v-bind:value="op.value"
                                    >
                                        {{op.text}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-sm-6">
                                <label>{{GetLabel(13)}}</label>
                                <select class="form-control" v-model="RegisterData.vocal_id">
                                    <option v-for="(op, index) in optVocal" :key="index"
                                        v-bind:value="op.value"
                                    >
                                        {{op.text}}
                                    </option>
                                </select>
                            </div>                    
                        </div>

                        <div class="row mt-2">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-8 text-center">
                                <b-button block variant="success" @click="Register()">{{GetLabel(14)}}</b-button>
                            </div>
                            <div class="col-sm-2"></div>
                        </div>
                    </div>
                </div>

                <div class="row" v-else>
                    <div class="col-sm-12 text-center">
                        <h2>{{GetLabel(15)}}: <b class="text-success">{{MemberNo}}</b></h2>
                        <h3>{{GetLabel(16)}}</h3>
                    </div>                    
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import SiteDataServ from '../services/SiteDataService.vue'
import EventBus from "../Event-Bus.vue";

export default {
    data(){
        return{
            OnRegister:true,
            RegisterData:{
                mbtype_id: null,
                mbcode:"",
                title: "",
                name: "",
                surname: "",
                nickname: "",
                addr: "",
                phone: "",
                email: "",
                lineid: "",
                church_id: null,
                rbch_id: null,
                vocal_id: null,
                expdate: null
            },
            optMBType:[
                { value:null, text: "--Select Type--" }
            ],
            optVocal:[
                { value:null, text: "--Select Vocal--" }
            ],
            optChurch:[
                { value:null, text: "--Select Church--" }
            ],
            optRBCH:[
                { value:null, text: "--Select Responsibility--" }
            ],
            lsMBT:[],
            RegisFee:"",
            MemberNo:"",
            lang:"en",
            i18n:[
                {id: 0, en:"CMA Member Register", th:"สมัครสมาชิก สมาคมดนตรีคริสตจักร" },
                {id: 1, en:"Member Type", th:"ประเภทสมาชิก" },
                {id: 2, en:"Church", th:"คริสตจักร" },
                {id: 3, en:"Title", th:"คำนำหน้า" },
                {id: 4, en:"Name", th:"ชื่อ" },
                {id: 5, en:"Surname", th:"นามสกุล" },
                {id: 6, en:"Nickname", th:"ชื่อเล่น" },
                {id: 7, en:"Birthdate", th:"วันเกิด" },
                {id: 8, en:"Phone", th:"โทรศัพท์" },
                {id: 9, en:"Address", th:"ที่อยู่" },
                {id: 10, en:"Email", th:"อีเมล์" },
                {id: 11, en:"LineId", th:"ไลน์ไอดี" },
                {id: 12, en:"Responsibility", th:"หน้าที่ความรับผิดชอบ" },
                {id: 13, en:"Vocal", th:"โทนเสียง" },
                {id: 14, en:"Register", th:"สมัครสมาชิก" },
                {id: 15, en:"You MemberNo", th:"รหัสสมาชิก" },
                {id: 16, en:"Please donate and wait admin approve.", th:"กรุณาชำระค่าสมาชิกและรอเจ้าหน้าที่ดำเนินการ" },
            ]
        }
    },
    methods:{
        GetMemberTypeLists(){
            let firstOpt = { value:null, text: "--Select Type--" };
            this.optMBType = [];
            this.optMBType.push(firstOpt);
            SiteDataServ.MemberTypeGets()
                .then(res => {
                    let FDs = res.data;
                    this.lsMBT = FDs;
                    let opt = this.ListToOpt(FDs, "mbtype_id", "typename", firstOpt);
                    this.optMBType = opt;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        GetMemberFee(){
            console.log(this.RegisterData.mbtype_id);
            this.RegisFee = "";
            console.log(this.lsMBT);
            let MBT = this.lsMBT.filter(f => f.mbtype_id == this.RegisterData.mbtype_id);
            if(MBT != null && MBT.length > 0){
                this.RegisFee = MBT[0].regisfee;
            }
        },
        ListToOpt(IList, FieldValue, FieldText, firstOpt){
            let result = [];
            if(firstOpt != null)
                result.push(firstOpt);

            for(let i = 0; i < IList.length; i++){
                let IT = IList[i];
                let opt = {
                    value: (FieldValue == null? IT : IT[FieldValue]),
                    text: IT[FieldText]
                };
                result.push(opt);
            }
            return result;
        },
        GetChurchList(){
            let firstOpt = { value:null, text: "--Select Church--" };
            this.optChurch = [];
            this.optChurch.push(firstOpt);
            SiteDataServ.ChurchGets()
                .then(res => {
                    let FDs = res.data;
                    let opt = this.ListToOpt(FDs, "church_id", "churchname", firstOpt);
                    this.optChurch = opt;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        GetRespChurchList(){
            let firstOpt = { value:null, text: "--Select Responsibility--" };
            this.optRBCH = [];
            this.optRBCH.push(firstOpt);
            SiteDataServ.RespChurchGets()
                .then(res => {
                    let FDs = res.data;
                    let opt = this.ListToOpt(FDs, "rbch_id", "rcname", firstOpt);
                    this.optRBCH = opt;
                })
                .catch(err => {
                    console.log(err);
                });            
        },
        GetVocalList(){
            let firstOpt = { value:null, text: "--Select Vocal--" };
            this.optVocal = [];
            this.optVocal.push(firstOpt);
            SiteDataServ.VocalGets()
                .then(res => {
                    let FDs = res.data;
                    let opt = this.ListToOpt(FDs, "vocal_id", "name", firstOpt);
                    this.optVocal = opt;
                })
                .catch(err => {
                    console.log(err);
                });               
        },
        ValidateData(){
            let IsOK = false;
            let Notice = "";

            if(this.RegisterData.mbtype_id == null)
                Notice += "Please insert <b class='text-danger'>Member Type</b><br/>";

            if(this.RegisterData.title == "")
                Notice += "Please insert <b class='text-danger'>Title</b><br/>";
            
            if(this.RegisterData.name == "")
                Notice += "Please insert <b class='text-danger'>Name</b><br/>";

            if(this.RegisterData.surname == "")
                Notice += "Please insert <b class='text-danger'>Surname</b><br/>";

            if(this.RegisterData.nickname == "")
                Notice += "Please insert <b class='text-danger'>Nickname</b><br/>";
            
            if(this.RegisterData.addr == "")
                Notice += "Please insert <b class='text-danger'>Address</b><br/>";

            if(this.RegisterData.phone == "")
                Notice += "Please insert <b class='text-danger'>Phone</b><br/>";  
            
            if(this.RegisterData.email == "")
                Notice += "Please insert <b class='text-danger'>Email</b><br/>"; 
            
            if(this.RegisterData.lineid == "")
                Notice += "Please insert <b class='text-danger'>LineID</b><br/>"; 
            
            if(this.RegisterData.church_id == "")
                Notice += "Please insert <b class='text-danger'>Church</b><br/>"; 
            
            if(this.RegisterData.rbch_id == "")
                Notice += "Please insert <b class='text-danger'>Responsibility</b><br/>"; 
            
            if(this.RegisterData.vocal_id == "")
                Notice += "Please insert <b class='text-danger'>Vocal</b><br/>"; 

            if(Notice != ""){
                this.$swal({
                    title: "Register data invalid data!!!",
                    html: Notice,
                    icon: "warning"
                })
                IsOK = false;
            }
            else{
                IsOK = true;
            }

            return IsOK;
        },
        Register(){
            let IsOk = this.ValidateData();
            if(IsOk == false)
                return;

            SiteDataServ.MemberRegister(this.RegisterData)
                .then(res => {
                    this.$swal({
                            icon: 'success',
                            title: 'Member Register',
                            html: 'Register Complete.'
                    });
                    this.MemberNo = res.data.mbcode;
                    this.OnRegister = false;
                })
                .catch(err => {
                    this.$swal({
                            icon: 'error',
                            title: 'Member Register',
                            html: err
                    });
                })
        },
        GetLabel(id){
            let result = "";
            let _lb = this.i18n.filter(f => f.id == id);
            if(_lb != null && _lb.length > 0)
                result = (this.lang == "en" ? _lb[0].en : _lb[0].th);

            return result;
        }
    },
    mounted(){
        this.GetChurchList();
        this.GetRespChurchList();
        this.GetVocalList();
        this.GetMemberTypeLists();

        let tVue = this;
        EventBus.$on("siteLanguageChange", (_lang) => {
            tVue.lang = _lang;
        });
    }
}
</script>

<style scoped>
ul:nth-of-type(1) {
  list-style-type: decimal;
  text-align: left;
}
</style>